.root {
  @apply container relative my-20;
}

.blue_polygon {
  @apply absolute -left-4 top-32 -z-10 h-[274px] w-[250px] lg:-left-24 lg:h-[647px] lg:w-[460px];
}

.dash_arrow {
  @apply absolute bottom-0 right-0 top-52 -z-10 sm:block lg:-bottom-32 lg:-right-20 lg:top-auto xl:block;
}

.layout {
  @apply relative grid grid-cols-1 gap-10 lg:grid-cols-3;
}
.items {
  @apply grid gap-6 lg:grid-cols-2;
}
