.button {
  @apply relative flex w-full items-center gap-2 rounded-xl border border-gray-300 bg-white px-5 py-4 text-left text-gray-400 focus:border-primary disabled:opacity-60;
}
.button_label {
  @apply flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-gray-800;
}

.button_multitrip_mobile {
  @apply z-10 -ml-8 flex h-11 w-11 flex-col items-center justify-center rounded-full bg-primary font-semibold text-white shadow-lg;
}
.dropdown {
  @apply w-full rounded-3xl sm:w-[667px];
}

.content {
  @apply flex flex-1 flex-col gap-6 overflow-hidden bg-muted p-4 md:rounded-3xl;
}
