.section {
  @apply relative mx-auto my-20 max-w-7xl;
}
.root {
  @apply container relative;
}

.ellipse {
  @apply absolute -bottom-52 right-0 -z-10 hidden lg:block;
}

.badge {
  @apply mx-auto mb-3;
}

.polygon {
  @apply absolute left-0 top-1/2 -mt-36 hidden lg:block;
}
.polygon_blue {
  left: -224px;
  top: -640px;
  @apply absolute -z-10 hidden lg:block;
}

.illustration {
  @apply absolute right-0 top-5 hidden lg:block;
}

@media (min-width: 1440px) {
  .polygon {
    @apply -left-40;
  }
}
@media (min-width: 1536px) {
  .illustration {
    @apply -right-20;
  }
  .ellipse {
    @apply -right-20;
  }
}
.description {
  @apply mx-auto mb-10 max-w-3xl text-center;
}

.wrapper {
  @apply relative flex flex-col gap-4 rounded-3xl bg-gray-100 bg-opacity-40 p-4 backdrop-blur-sm md:p-6;
}

.actions {
  @apply mt-2 flex justify-center;
}
