.route_grid {
  @apply grid grid-cols-[1fr_auto_auto] items-center gap-2 md:grid-cols-[auto_2fr_1fr_auto];
}

.route_index {
  @apply hidden font-semibold text-primary md:block;
}

.route_grid_item {
  @apply relative grid grid-cols-2 gap-2;
}

.swap_button {
  @apply right-[52%] sm:right-1/2;
}

.add_route_button {
  @apply w-full text-wrap border-dashed leading-tight;
}

.remove_route {
  @apply flex h-[26px] w-[26px] items-center justify-center sm:h-8 sm:w-8;
}
.remove_route_button {
  @apply rounded-full border border-error bg-white text-error;
}
