.root {
  @apply absolute inset-y-0 flex items-center;
}

.start_md {
  @apply start-3;
}
.start_lg {
  @apply start-4;
}
.end_md {
  @apply end-3;
}
.end_lg {
  @apply end-4;
}
