.root {
  @apply relative my-12 gap-5 overflow-hidden;
}
.layout {
  @apply grid grid-cols-1 gap-10 lg:grid-cols-10;
}
.left_container {
  @apply flex flex-col justify-center gap-6 text-center lg:col-span-3 lg:justify-start lg:text-left;
}
.right_container {
  @apply relative lg:col-span-7;
}
.slide {
  @apply grid grid-cols-3 grid-rows-2 gap-4 pl-4;
}
.card {
  @apply relative flex flex-col overflow-hidden rounded-2xl;
}
.row_span {
  @apply row-span-2 h-96;
}
.col_span {
  @apply col-span-2;
}
.card_content {
  @apply relative flex flex-1 flex-col justify-end bg-opacity-50 bg-gradient-to-t from-[#0f172966] to-transparent p-4;
}
.card_placeholder {
  @apply relative flex flex-1 flex-col justify-end bg-[#D9DBDC] p-4;
}
.card_title a {
  @apply flex flex-col text-white;
}
.card_title a:after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.slide_image {
  @apply rounded-2xl object-cover;
}
.carousel_buttons {
  @apply mt-10 hidden gap-4 lg:flex;
}
.button_mobile {
  @apply flex items-center justify-center lg:hidden;
}
.illustration {
  @apply absolute -left-28 top-0 -z-10;
}
