.root {
  @apply mb-8 mt-12 bg-top bg-repeat-x;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="1920" height="454" viewBox="0 0 1920 454" fill="none"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M4097.19 453.584H0V49.9874C112.007 78.5267 264.442 80.6315 334.622 44.4286C413.96 3.50116 544.227 10.9999 628.874 46.5711C659.151 59.2888 810.945 84.7791 868.815 59.536C934.736 30.7769 1086.41 6.11062 1176.66 44.2912C1427.28 150.318 1644.93 -65.7183 1824.44 41.929C1897.42 85.6856 2040.93 54.6295 2115.71 30.2928C2361.02 -49.5018 2428.52 56.4699 2589.53 56.4699C2800.88 51.0038 2785.25 5.62652 2896.26 2.76985C2958.51 1.1767 3043.22 56.4699 3255.04 56.4699C3444.4 56.4699 3383.96 8.59307 3537.97 3.59389C3684.69 -1.15809 3830.42 101.984 4016.8 43.2578C4038.33 36.4732 4066.22 29.8808 4097.13 24.8267V453.557L4097.19 453.584Z" fill="%23F9F9F9"%3E%3C/path%3E%3C/svg%3E');
}
.wrapper {
  @apply mx-auto flex max-w-xl flex-col items-center justify-center gap-10 lg:flex-row;
}
.image {
  @apply hidden flex-shrink-0 lg:block;
}
.content {
  @apply flex flex-1 flex-col items-center justify-center gap-6 py-6;
}
.title {
  @apply text-center text-2xl md:text-3xl;
}
.description {
  @apply text-center;
}
