.root {
  @apply mt-1 text-sm;
}
.color_default {
  @apply text-gray-600;
}
.color_error {
  @apply text-error;
}
.color_success {
  @apply text-success;
}
