.wrapper {
  @apply bg-secondary p-10 text-white sm:rounded-3xl;
}
.content {
  @apply flex flex-col gap-4 lg:flex-row lg:items-center lg:gap-6;
}
.title {
  @apply text-4xl font-bold;
}
.title > span {
  @apply block text-primary;
}
.description {
  @apply text-lg lg:basis-1/3;
}
.form {
  @apply flex flex-1 flex-col gap-4 sm:flex-row;
}
.form > button {
  @apply rounded-xl p-4;
}
.text {
  @apply mt-4 text-center;
}
.success_text {
  @apply text-green-600;
}
.error_text {
  @apply text-error;
}
