.root {
  @apply fixed bottom-0 left-0 right-0 top-0 z-50 flex flex-col;
}

.content {
  @apply flex flex-1 flex-col overflow-hidden bg-white;
}

.header {
  @apply flex shrink-0 items-center p-4;
}

.title {
  @apply flex-1 text-lg font-semibold;
}

.body {
  @apply flex flex-1 flex-col overflow-hidden;
}

.actions {
  @apply flex shrink-0 items-center p-4;
}
