.root {
  @apply absolute z-10 origin-[0] -translate-y-1 scale-[.70] transform duration-300;
}
.size_md {
  @apply start-3 top-1;
}
.size_lg {
  @apply start-4 top-2;
}
.color_error {
  @apply text-error;
}
.color_success {
  @apply text-success;
}
