.root {
  @apply block w-full text-ellipsis border bg-white text-default focus:outline-none disabled:opacity-60;
}
.root:placeholder-shown ~ label {
  @apply top-1/2 -translate-y-1/2 scale-100;
}
.root:focus ~ label {
  @apply -translate-y-1 scale-[.70];
}
.color_default {
  @apply border-gray-300 focus:border-primary;
}
.color_default:focus ~ label {
  @apply text-primary;
}
.color_error {
  @apply border-error focus:border-error;
}
.color_success {
  @apply border-success focus:border-success;
}
.size_md {
  @apply rounded-lg px-3 py-3.5;
}
.size_md:focus ~ label {
  @apply top-0.5;
}
.size_lg {
  @apply rounded-xl p-4 sm:px-5 sm:py-4;
}
.size_lg:focus ~ label {
  @apply top-2;
}
.with-startAdornment-md {
  @apply pl-10;
}
.with-startAdornment-lg {
  @apply pl-12;
}
.with-endAdornment-md {
  @apply pr-10;
}
.with-endAdornment-lg {
  @apply pr-12;
}
