.root {
  @apply grid grid-cols-12 gap-2;
}

.input_col {
  @apply col-span-12 lg:col-span-7;
}

.input_grid {
  @apply relative grid grid-cols-1 gap-2 sm:grid-cols-2;
}

.date_col {
  @apply col-span-12 lg:col-span-3;
}

.button_col {
  @apply col-span-12 lg:col-span-2;
}

.swap_button {
  @apply right-8 rotate-90 sm:right-1/2 sm:rotate-0;
}
