.embla_container {
  @apply -ml-4 -mt-4 flex h-full flex-col md:flex-row;
}

.embla_slide {
  transform: translate3d(0, 0, 0);
  @apply flex-shrink-0 flex-grow-0 basis-1/2 pl-4 pt-4;
}
.month_wrapper {
  @apply mx-auto flex max-w-80 flex-col gap-4;
}
.week_grid {
  @apply grid grid-cols-7;
}
.calendar_grid {
  @apply grid grid-cols-7 border-l border-t;
}
.day {
  @apply relative flex h-11 w-11 items-center justify-center border-b border-r bg-white;
}
.day_today {
  font-size: 8px;
  @apply absolute left-1/2 top-[2px] -translate-x-1/2 font-semibold uppercase tracking-wide;
}
.day_selected {
  @apply border-primary bg-primary text-white;
}
.day_range_between {
  @apply bg-[#DDEFFF];
}
.day_disabled {
  @apply text-gray-400;
}
