.root {
  @apply relative bg-gray-50 pb-4 pt-10 lg:bg-transparent;
}
.polygon {
  @apply absolute -left-64 top-10 -z-10 hidden lg:block;
}
.carousel {
  @apply overflow-hidden p-6;
}
.card {
  @apply relative flex flex-shrink-0 flex-grow-0 basis-3/4 flex-col items-center gap-0 rounded-2xl bg-white p-4 text-center shadow-md md:basis-1/2 lg:mx-0 lg:basis-1/3 lg:flex-row lg:gap-6 lg:rounded-none lg:bg-transparent lg:shadow-none;
}
.card_content {
  @apply text-center lg:text-start;
}
.card_title {
  @apply my-2 font-bold;
}
.card_title a {
  @apply text-default;
}
.card_title a:after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
