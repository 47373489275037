.card {
  @apply grid grid-cols-12 items-center gap-x-4 gap-y-4 rounded-2xl bg-white p-4 shadow-md lg:gap-x-6;
}

.destination {
  @apply order-1 col-span-8 flex flex-wrap items-center gap-x-2 text-primary md:col-span-3;
}

.destination_name {
  @apply font-bold;
}

.arrow_icon {
  @apply h-4 w-4;
}

.duration {
  @apply order-2 col-span-4 flex items-center justify-end gap-1 text-gray-600 md:order-3 md:col-span-3 md:justify-center;
}

.duration_icon {
  @apply h-4 w-4;
}

.company {
  @apply order-3 col-span-12 flex items-center gap-2 md:order-2 md:col-span-3;
}

.company_logo {
  @apply rounded-md border;
}

.company_name {
  @apply text-sm font-bold;
}

.vessel_name {
  @apply text-sm;
}

.price_container {
  @apply order-4 col-span-12 flex items-center justify-between gap-6 md:col-span-3 md:justify-end;
}

.price {
  @apply font-bold;
}
