.root {
  @apply relative grid w-full grid-flow-col items-center rounded-full border bg-white p-1 sm:w-auto sm:border-0;
}

.item {
  @apply z-10 min-h-12 rounded-full px-3 text-center leading-tight lg:px-6;
}

.animated_item {
  @apply absolute rounded-full bg-primary transition-all duration-200 ease-out;
}
